export default [
  {
    path: "/",
    redirect: '/login',
    component: () => import("@/views/login/index.vue"),
    children: [
      {
        path: "/login",
        name: "login",
        meta: { hideSide: true, hideHeader: true },
        component: () => import("@/views/login/module/login.vue"),
      },
      {
        path: "/registered",
        name: "registered",
        meta: { hideSide: true, hideHeader: true },
        component: () => import("@/views/login/module/registered.vue"),
      },
      {
        path: "/reset",
        name: "reset",
        meta: { hideSide: true, hideHeader: true },
        component: () => import("@/views/login/module/reset.vue"),
      }
    ]
  },
  /*{
    path: "/",
    name: "frontIndex",
    meta: { hideSide: true, hideHeader: true },
    component: () => import("@/views/frontIndex/index.vue"),
  },*/
  {
    path: "/404",
    name: "404",
    meta: { hideSide: true },
    component: () => import("@/views/errorPage/404.vue"),
  },
  {
    path: "/401",
    name: "401",
    meta: { hideSide: true },
    component: () => import("@/views/errorPage/401.vue"),
  },
  {
    path: "/home", //首页
    name: 'home',
    component: () => import("@/views/home/index.vue")
  },
  {
    path: "/privilege", //会员特权
    name: "privilege",
    meta: { hideSide: true },
    component: () => import("@/views/privilege/index.vue")
  },
  {
    path: "/homeDownload", //国内素材下载
    name: "homeDownload",
    component: () => import("@/views/homeDownload/index.vue")
  },
  {
    path: "/materialDownload", //素材下载
    name: "materialDownload",
    component: () => import("@/views/materialDownload/index.vue")
  },
  {
    path: "/materialCenter", //素材中心
    name: "materialCenter",
    component: () => import("@/views/materialCenter/index.vue")
  },
  {
    path: "/materialPreview", //素材中心-预览
    name: "materialPreview",
    meta: { hideSide: true },
    component: () => import("@/views/materialCenter/preview.vue")
  },
  {
    path: "/materialSearch", //素材中心-搜索
    name: "materialSearch",
    component: () => import("@/views/materialCenter/search.vue")
  },
  {
    path: "/materialSearch", //素材查询
    name: "materialSearch",
    component: () => import("@/views/materialCenter/search.vue")
  },
  {
    path: "/pay", //支付宝/微信充值
    name: "pay",
    component: () => import("@/views/pay/index.vue")
  },
  {
    path: "/cardStockPay", //卡劵充值
    name: "cardStockPay",
    component: () => import("@/views/cardStockPay/index.vue")
  },
  {
    path: "/statement", //使用声明
    name: "statement",
    component: () => import("@/views/statement/index.vue")
  },
  {
    path: "/blog", //博客
    name: "blog",
    component: () => import("@/views/blog/index.vue")
  },
  {
    path: "/downloadHistory", //下载历史
    name: "downloadHistory",
    component: () => import("@/views/downloadHistory/index.vue")
  },
  {
    path: "/personlCenter", //个人中心
    name: "personlCenter",
    component: () => import("@/views/personlCenter/index.vue")
  },
  {
    path: "/temporaryDownload/:tempToken", //临时下载
    name: "temporaryDownload",
    props: true,
    meta: { hideSide: true, hideHeader: true },
    component: () => import("@/views/temporaryDownload/index.vue")
  },
  {
    path: "/tempDown/:tempToken", //临时下载
    name: "tempDown",
    props: true,
    meta: { hideSide: true, hideHeader: true },
    component: () => import("@/views/tempDown/index.vue")
  },
  {
    path: "/qxDown/:tempToken", //临时下载
    name: "qxDown",
    props: true,
    meta: { hideSide: true, hideHeader: true },
    component: () => import("@/views/qxDown/index.vue")
  },
  {
    path: "/byDown/:tempToken", //临时下载
    name: "byDown",
    props: true,
    meta: { hideSide: true, hideHeader: true },
    component: () => import("@/views/byDown/index.vue")
  },
  {
    path: "/balance", //收支 订单
    meta: { hideSide: true },
    component: () => import("@/views/balance/index.vue")
  },
  {
    path: "/members", //收支 订单
    meta: { hideSide: true },
    component: () => import("@/views/members/index.vue")
  },
  {
    path: "/materialPreview", //收支 订单
    meta: { hideSide: true },
    component: () => import("@/views/materialCenter/preview.vue")
  },
  {
    path: "/accountInfo", //查看余额
    name: "accountInfo",
    meta: { dialogVisible: false },
    component: () => import("@/views/personlCenter/accountInfoIndex.vue")
  },
  ,
  {
    path: "/freePage",
    name: "freePage",
    meta: { hideSide: true, hideHeader: true },
    component: () => import("@/views/freePage/index.vue"),
  },
]

//-----1元站------
/*
export default [
  {
    path: "/tempDown/:tempToken", //临时下载
    name: "tempDown",
    props: true,
    meta: { hideSide: true, hideHeader: true },
    component: () => import("@/views/tempDown/index.vue")
  },
  {
    path: "/404",
    name: "404",
    meta: { hideSide: true,hideHeader: true },
    component: () => import("@/views/errorPage/404.vue"),
  }
]*/
