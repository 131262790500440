<template>
  <nav>
    <ul class="list">
      <li
        class="item"
        v-for="(item,index) in menus"
        :key="index"
        :class="index == currentMenu ? 'active' : ''"
        @click="changeMenu(index,item)"
      >
        <router-link :to="{ name: item.link}">{{item.name}}</router-link>
        <div class="pop-center-list" v-if="index == 1">
          <div class="code">
            <img :src="wechatImg" alt />
            <p>微信扫码</p>
          </div>
        </div>
      </li>
      <li class="item" v-if="dropDownMenus.length > 0">
        <span class="show-more">
          <i class="iconfont icongengduo"></i>
        </span>
        <ul class="pop-list">
          <li v-for="(v,vIndex) in dropDownMenus" :key="vIndex" @click="changeDropDownMenu">
            <router-link :to="{ name: v.link}">{{v.name}}</router-link>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script>

  import { message } from '@/utils/resetMessage';
export default {
  props: {},
  data() {
    return {
      menus: [], //菜单列表
      dropDownMenus: [], //下拉菜单列表
      wechatImg: require("@/assets/images/code.png")
    };
  },
  computed: {
    currentMenu: {
      get() {
        return this.$store.state.menu.headerMenuId;
      },
      set(val) {}
    }
  },
  mounted() {
    this.getMenus();
  },
  methods: {
    //获取菜单列表
    getMenus() {
      this.menus = this.constants.headerMenu;
      this.dropDownMenus = this.constants.headerMoreMenus;
    },
    //改变菜单切换状态
    changeMenu(index, params) {
      if (index == 1) {
        this.$store.commit("asideMenuId", 4);
      } else {
        this.$store.commit("asideMenuId", null);
      }

      if(index==3){//在线客服
        let isLoggedIn = (this.$store.state.user.token !== null || this.$store.state.user.token !== "")
        && (this.$store.state.user.loginNameType!=null )? true : false;
        if(!isLoggedIn){//未登录

          message.error({
            message: '您还没登录用户，请登录',
            showClose: true,
            duration: 2000,
            offset: 100
          });
          return;
        }
        //console.log("Tawk_API:",Tawk_API.toggle);
        if(Tawk_API.toggle) {//判断有没有初始化好
          Tawk_API.toggle();//打开客服页面
        }else {
          message.error({
            message: '正在初始化，请重试',
            showClose: true,
            duration: 2000,
            offset: 100
          });
          return;
        }
      }
      if (index !== 3) this.$store.commit("headerMenuId", index);
      this.currentMenu = index;
    },
    //改变下拉菜单切换状态
    changeDropDownMenu() {
      this.currentMenu = null;
    }
  }
};
</script>

<style lang="scss" scoped>
nav {
  margin-left: 42px;
  margin-right: auto;
  position: relative;
  height: 100%;
  width: auto;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.list {
  margin: 0;
  padding: 0;
  position: relative;
  display: flex;
  height: 100%;
  .item {
    margin-right: 40px;
    position: relative;
    &:hover {
      .pop-center-list {
        display: block;
      }
    }
    &:not(:last-child) {
      margin-right: 40px;
    }
    &:last-child:hover {
      .pop-list {
        display: block;
      }
    }
    &.active {
      a {
        color: #0773fc;
        font-weight: 700;
      }
    }
    a {
      position: relative;
      display: inline-block;
      line-height: 70px;
      font-size: 14px;
      color: #333;
      cursor: pointer;
      &:hover {
        position: relative;
        color: #0773fc;
      }
    }
    .show-more {
      position: relative;
      display: block;
      width: 3em;
      line-height: 70px;
      cursor: pointer;
      &:hover {
        .iconfont {
          color: #0773fc;
        }
      }
      .iconfont {
        font-size: 20px;
        color: #1b2337;
      }
    }
  }
}
.pop-list {
  width: 100px;
  text-align: left;
  li {
    margin-right: 0;
    cursor: pointer;
    font-size: 12px;
    color: #333;
    &:hover {
      background-color: #f8f8f8;
      a {
        color: #0773fc;
      }
    }
    a {
      display: block;
      padding-left: 16px;
      height: 32px !important;
      line-height: 32px !important;
      color: #333;
    }
  }
}
.pop-center-list,
.pop-list {
  position: absolute;
  display: none;
  top: 68px;
  left: 50%;
  margin-left: -50px;
  overflow: hidden;
  background-color: #fff;
  transition: all 0.4s ease;
  border: 1px solid #eee;
  box-shadow: 0 8px 16px 0 rgba(10, 15, 31, 0.14);
  border-radius: 4px;
}
.pop-center-list {
  margin-left: -80px;
  width: 160px;
  z-index: 22;
  .code {
    padding: 24px 0 10px;
    text-align: center;
    img {
      height: 97px;
      width: 97px;
    }
    p {
      margin-top: 6px;
      font-size: 12px;
      color: #666;
    }
  }
}
</style>
