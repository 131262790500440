const user = {
  state: {
    token: null,
    userId: null,
    userName: null,
    roleCode: null,
    loginNameType: null,
    vipTitle: null,
    vipType:null,
    vipSubTitle: null,
    vipBannerName: null
  },

  mutations: {
    token: (state, token) => {
      state.token = token;
    },
    userId: (state, userId) => {
      state.userId = userId;
    },
    userName: (state, userName) => {
      state.userName = userName;
    },
    roleCode: (state, roleCode) => {
      state.roleCode = roleCode;
    },
    loginNameType: (state, loginNameType) => {
      state.loginNameType = loginNameType;
    },
    vipTitle: (state, vipTitle) => {
      state.vipTitle = vipTitle;
    },
    vipType: (state, vipType) => {
      state.vipType = vipType;
    },
    vipSubTitle: (state, vipSubTitle) => {
      state.vipSubTitle = vipSubTitle;
    },
    vipBannerName: (state, vipBannerName) => {
      state.vipBannerName = vipBannerName;
    },
  },

  actions: {
    setVipTitle({ commit }, vipTitle) {
      commit('vipTitle', vipTitle)
    },
    setVipType({ commit }, vipTitle) {
      commit('vipType', vipTitle)
    },
    setVipSubTitle({ commit }, vipSubTitle) {
      commit('vipSubTitle', vipSubTitle)
    },
    setVipBannerName({ commit }, vipBannerName) {
      commit('vipBannerName', vipBannerName)
    },
  }
};

export default user;
