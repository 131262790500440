<template>
  <div class="message-list">
    <ul>
      <li v-for="(item,index) in messageList" :key="index">
        <div class="title-box">
          <a class="title">{{item.title}}</a>
          <span class="time">{{item.time}}</span>
        </div>
        <div class="text-box">
          <a>
            <span>{{item.label}}</span>
            <em>{{item.link}}</em>
          </a>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    messageList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.message-list {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  ul {
    padding: 0 24px;
    li {
      width: 100%;
      padding: 32px 0;
      background: #fff;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      // &:last-child {
      //   border-bottom: 0;
      // }
      .title-box {
        .title {
          float: left;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.8);
          font-weight: 700;
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        .time {
          float: right;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.4);
          line-height: 16px;
          cursor: default;
        }
      }
      .text-box {
        width: 100%;
        padding-top: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.6);
        cursor: pointer;
        a {
          display: block;
          &:hover {
            color: rgba(0, 0, 0, 0.8);
          }
          span {
            color: #666;
          }
          em {
            color: #0773fc;
          }
          span,
          em {
            font-size: 12px;
          }
          em {
            margin-left: 2px;
            color: #0773fc;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
</style>