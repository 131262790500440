<template>
  <div id="app" v-if="!this.isMobileFlag">
    <v-header :showHeaderSearch="false" v-if="showHeader"></v-header>
    <div :class="!showLeftSlider ? 'temp-center index' : 'temp-center'">
      <div class="content">
        <div class="content-wrap" style="width:100%">
          <asideNav v-if="showLeftSlider"></asideNav>
          <div :class="{'rightContent': showLeftSlider}">
            <div class="slider">
              <router-view></router-view>
            </div>
          </div>
          <advert v-if="showAdvert"></advert>
        </div>
      </div>
    </div>
  </div>
  <div id="app" class="appLayout" v-else>
    <mheader v-if="showHeader"></mheader>
    <div class="content">
      <div class="content-wrap">
        <masideNav v-if="showLeftSlider"></masideNav>
        <div class="mrightContent">
            <div class="slider">
              <router-view></router-view>
            </div>
        </div>
        <advert v-if="showAdvert"></advert>
      </div>
    </div>
  </div>
</template>

<style rel="stylesheet/scss" lang="scss" scoped>
.appLayout {
  display: flex;
  .content {
    padding-right: 0;
    margin-top: 70px;
    display: flex;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
  }
}

.mrightContent {
  padding: 5px;
  max-width: 1920px;
  min-width: 460px;
  width: calc(100vw - 360px);
  box-sizing: content-box;
  .silder {
    position: relative;
    width: 100%;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.temp-center {
  width: 100%;
  position: relative;
  height: 100vh;
  overflow: auto;
  background: #fff;
  &.index {
    background: #fff;
    .content {
      margin-top: 0 !important;
    }
    .aside-nav {
      background: #f8f8fb;
    }
  }
  .content {
    padding-right: 0;
    margin-top: 70px;
    display: flex;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
  }
}
.rightContent {
  padding: 40px 90px 0 275px;
  max-width: 1920px;
  min-width: 560px;
  width: calc(100vw - 360px);
  box-sizing: content-box;
  .silder {
    position: relative;
    width: 100%;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

</style>
<script>
import header from "./components/header/header.vue";
import mheader from "./components/header/mheader.vue";
import asideNav from "./components/asideNav/asideNav.vue";
import masideNav from "./components/asideNav/masideNav.vue";
import advert from  "./views/advert/advert";
import MarqueeTips from 'vue-marquee-tips';
export default {
  name: "app",
  components: {
    "v-header": header,
    asideNav,
    advert,
    MarqueeTips,
    mheader,
    masideNav
  },
  computed: {
    showHeader() {
      return this.$store.state.common.showHeader;
    },
    showAdvert() {
      return false;
    },
    showLeftSlider() {
      return this.$store.state.common.showLeftSlider;
    },
    showAllPage() {
      return this.$store.state.common.showAllPage;
    }
  },
  mounted() {
    if(this.isMobile()){
      this.isMobileFlag = true;
    }else{
      this.isMobileFlag = false;
    }
  },
  data() {
    return {
      hasIndex: this.$store.state.menu.headerMenuId,
      tipMessge:'通知：由于特殊原因，原网址即将暂停访问，请收藏松子图库最新网址 <a href="http://vip.songzituku.com">vip.songzituku.com</a>, 以免后续出现无法访问的问题，感谢您的支持！',
      isMobileFlag:false
    };
  },
  methods:{
    isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      return flag;
    }
  }
};
</script>

