const menu = {
    state: {
        headerMenuId: null,
        asideMenuId: null
    },
    mutations: {
        headerMenuId: (state, headerMenuId) => {
            state.headerMenuId = headerMenuId;
        },
        asideMenuId: (state, asideMenuId) => {
            state.asideMenuId = asideMenuId;
        }
    }
}
export default menu; 