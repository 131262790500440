import { render, staticRenderFns } from "./asideNav.vue?vue&type=template&id=1434bffb&scoped=true&"
import script from "./asideNav.vue?vue&type=script&lang=js&"
export * from "./asideNav.vue?vue&type=script&lang=js&"
import style0 from "./asideNav.vue?vue&type=style&index=0&id=1434bffb&rel=stylesheet%2Fscss&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1434bffb",
  null
  
)

export default component.exports