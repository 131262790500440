export default {
    state: {
        showLeftSlider: true,
        showHeader: false,
        showAllPage: false,
        balanceIndex: 0,
        userInfo: null,
        sendCodeCount:120,
        showAdvert:false,
        showPost:false,
        isNewUser:false
    },
    mutations:{
        updateShowLeftSlider (state, showLeftSlider) {
            state.showLeftSlider = showLeftSlider
        },
        updateShowHeader (state, showHeader) {
            state.showHeader = showHeader
        },
        updateShowAllPage (state, showAllPage) {
            state.showAllPage = showAllPage
        },
        updateBalanceIndex(state, balanceIndex) {
            state.balanceIndex = balanceIndex
        },
        updateUserInfo(state, userInfo) {
            state.userInfo = userInfo
        },
        updateSendCodeCountState: (state, sendCodeCount) => {
            state.sendCodeCount = sendCodeCount;
        },
        updateShowAdvert (state, showAdvert) {
            state.showAdvert = showAdvert
        },
        updateShowPost (state, showPost) {
            state.showPost = showPost
        },
        updateIsNewUser (state, isNewUser) {
            state.isNewUser = isNewUser
        }

    },
    // 类似computed
    getters: {
        
    },
    actions: {
        setShowLeftSlider ({commit}, showLeftSlider) {
            commit('updateShowLeftSlider', showLeftSlider)
        },
        setShowAllPage ({commit}, showAllPage) {
            commit('updateShowAllPage', showAllPage)
        },
        setBalanceIndex ({commit}, balanceIndex) {
            commit('updateBalanceIndex', balanceIndex)
        },
        setUserInfo ({commit}, userInfo) {
            commit('updateUserInfo', userInfo)
        },
        setShowHeader ({commit}, showHeader) {
            commit('updateShowHeader', showHeader)
        },
        setShowAdvert ({commit}, showAdvert) {
            commit('updateShowAdvert', showAdvert)
        },
        setShowPost ({commit}, showPost) {
            commit('updateShowPost', showPost)
        },
        setShowIsNewUser ({commit}, isNewUser) {
            commit('updateIsNewUser', isNewUser)
        }
    }
}
