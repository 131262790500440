<template>
  <div class="search-wrap" v-if="showHeaderSearch">
    <div class="search">
      <el-autocomplete
        popper-class="autocomplete"
        v-model="state"
        :fetch-suggestions="querySearch"
        placeholder="请输入内容"
        @select="selectSearchInfo"
      >
        <template slot-scope="{ item }">
          <div class="name">{{ item.value }}</div>
          <span class="result">{{ item.result }}条结果</span>
        </template>
      </el-autocomplete>
      <button class="search-button" @click="search">搜全站</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showHeaderSearch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      state: "",
      restaurants: []
    };
  },
  mounted() {
    this.restaurants = this.loadAll();
  },
  methods: {
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return restaurant => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    //搜索列表
    loadAll() {
      return [
        { value: "Hot honey 首尔炸鸡（仙霞路）", result: 12 },
        { value: "新旺角茶餐厅", result: 32 },
        { value: "首尔炸鸡（仙霞路）", result: 56 }
      ];
    },
    selectSearchInfo(val) {
      this.$emit('selectSearch', val);
    },
    search() {
      this.$emit('clickSearch', this.state);
    }
  }
};
</script>

<style lang="scss" scoped>
.search-wrap {
  position: relative;
  display: flex;
  width: 100%;
  min-width: 332px;
  -ms-flex-negative: 2;
  flex-shrink: 2;
  align-items: center;
  justify-content: center;
  .search {
    position: relative;
    display: flex;
    max-width: 456px;
    height: 36px;
    align-items: center;
    border-radius: 22px;
  }
  .search-button {
    display: flex;
    width: 90px;
    min-width: 90px;
    height: 36px;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    background-color: #0773fc;
    border-radius: 0 22px 22px 0;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    cursor: pointer;
    z-index: 99;
  }
}
</style>